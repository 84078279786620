//
// preloader.scss
//

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--#{$prefix}secondary-bg);
  z-index: 9999;
}

[data-preloader="disable"] #preloader {
  opacity: 0;
  visibility: hidden;
}

#status {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  height: auto;
}
