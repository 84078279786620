@page {
  size: A4;
  margin: 0;
  padding: 10px; /* Sayfa kenarlarına 10px padding ekler */
}

/* Sayfa kırılımı sonrasında her sayfada padding'i artırma */
.page {
  page-break-before: always;
  padding: 10px; /* Sayfa içeriklerinin etrafına padding ekler */
}
