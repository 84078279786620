/* Agreement Content için Genel Ayarlar */
.agreement-content {
  line-height: 1.5 !important;
  font-size: 10px !important;
  margin-bottom: 0 !important;
  color: black !important;
}
/* <p> Etiketine Özel Stil */
.agreement-content p {
  line-height: 1.5 !important;
  font-size: 10px !important;
  margin-bottom: 0 !important;
  color: black !important;
}

/* Başlıklar */
.agreement-content h1 {
  font-size: 2.03125rem !important;
  margin-top: 0.5rem !important;
  margin-bottom: 0.2rem !important;
  color: black !important;
  font-weight: 600;
}
.agreement-content h2 {
  font-size: 1.625rem !important;
  margin-top: 0.5rem !important;
  margin-bottom: 0.2rem !important;
  color: black !important;
  font-weight: 600;
}
.agreement-content h3 {
  font-size: 1.421875rem !important;
  margin-top: 0.5rem !important;
  margin-bottom: 0.2rem !important;
  color: black !important;
  font-weight: 600;
}
.agreement-content h4 {
  font-size: 1.21875rem !important;
  margin-top: 0.5rem !important;
  margin-bottom: 0.2rem !important;
  color: black !important;
  font-weight: 600;
}
.agreement-content h5 {
  font-size: 1.015625rem !important;
  margin-top: 0.5rem !important;
  margin-bottom: 0.2rem !important;
  color: black !important;
  font-weight: 600;
}
.agreement-content h6 {
  font-size: 0.8125rem !important;
  margin-top: 0.5rem !important;
  margin-bottom: 0.2rem !important;
  color: black !important;
  font-weight: 600;
}

/* Font Boyutları */
.agreement-content .ql-size-10 {
  font-size: 10px !important;
}
.agreement-content .ql-size-11 {
  font-size: 11px !important;
}
.agreement-content .ql-size-12 {
  font-size: 12px !important;
}
.agreement-content .ql-size-14 {
  font-size: 14px !important;
}

/* Kod Blokları */
.agreement-content pre {
  font-family: "Poppins", sans-serif !important;
  font-size: 10px !important;
  margin-bottom: 0 !important;
}
