/* Quill Editörü için CSS */
.ql-editor {
  line-height: 1.5 !important; /* Temadaki satır yüksekliği */
  font-size: 10px !important; /* Temadaki temel font boyutu */
  margin-bottom: 0 !important;
  color: black !important;
}

/* Quill Editöründe Başlıkları Temaya Uygulamak */
.ql-editor p {
  line-height: 1.5 !important;
  font-size: 10px !important;
  margin-bottom: 0 !important;
  color: black !important;
}

.ql-editor h1 {
  font-size: 2.03125rem !important; /* $h1-font-size = 0.8125rem * 2.5 */
  margin-top: 0.5rem !important;
  margin-bottom: 0.2rem !important;
  color: black !important;
  font-weight: 600;
}

.ql-editor h2 {
  font-size: 1.625rem !important; /* $h2-font-size = 0.8125rem * 2 */
  margin-top: 0.5rem !important;
  margin-bottom: 0.2rem !important;
  color: black !important;
  font-weight: 600;
}

.ql-editor h3 {
  font-size: 1.421875rem !important; /* $h3-font-size = 0.8125rem * 1.75 */
  margin-top: 0.5rem !important;
  margin-bottom: 0.2rem !important;
  color: black !important;
  font-weight: 600;
}

.ql-editor h4 {
  font-size: 1.21875rem !important; /* $h4-font-size = 0.8125rem * 1.5 */
  margin-top: 0.5rem !important;
  margin-bottom: 0.2rem !important;
  color: black !important;
  font-weight: 600;
}

.ql-editor h5 {
  font-size: 1.015625rem !important; /* $h5-font-size = 0.8125rem * 1.25 */
  margin-top: 0.5rem !important;
  margin-bottom: 0.2rem !important;
  color: black !important;
  font-weight: 600;
}

.ql-editor h6 {
  font-size: 0.8125rem !important; /* $h6-font-size = $font-size-base */
  margin-top: 0.5rem !important;
  margin-bottom: 0.2rem !important;
  color: black !important;
  font-weight: 600;
}

/* Eğer font boyutları özel tanımlandıysa, bunları da burada ekleyebiliriz */
.ql-editor .ql-size-10 {
  font-size: 10px !important;
}

.ql-editor .ql-size-11 {
  font-size: 11px !important;
}

.ql-editor .ql-size-12 {
  font-size: 12px !important;
}

.ql-editor .ql-size-14 {
  font-size: 14px !important; /* $font-size-custom'dan 14px */
}

/* Quill Toolbar Font Boyutlarını Temaya Uygulamak */
.ql-size-small {
  font-size: 12px !important; /* Küçük boyut */
}

.ql-size-medium {
  font-size: 16px !important; /* Orta boyut */
}

.ql-size-large {
  font-size: 20px !important; /* Büyük boyut */
}

/* Quill Editöründe Kod Blokları için Font */
.ql-editor pre {
  font-family: "Poppins", sans-serif !important;
  font-size: 10px !important;
  margin-bottom: 0 !important;
}
